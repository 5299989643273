import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <section className="bg-primary-about text-white mb-0" id="about">
        <div className="container">
          <h2 className="text-center text-uppercase text-white">WHAT I DO</h2>
          <div class="divider-custom divider-light">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <i class="fas fa-star"></i>
            </div>
            <div class="divider-custom-line"></div>
          </div>
          <div className="row">
            <div className="col-lg-4 ml-auto">
              <p className="lead">
                I write code! Frontend & Backend business logics. <br />{" "}
                Flexboxing, Gridding, Sassing, Componentizing, Fetching,
                Promising, Routing, Querying are few keywords better describes
                my role.
              </p>
            </div>
            <div className="col-lg-4 mr-auto">
              <p className="lead">
                Create responsive Web Apps. <br /> Do fun stuff with React
                Native based Mobile Apps. <br />
                Spend free time with Sketch and XD.
              </p>
            </div>
          </div>

          <div className="text-center mt-4">
            <a
              className="btn btn-xl btn-outline-light"
              href="/Resume - Shashank Yadav.pdf"
              target="_blank"
            >
              <i className="fas fa-download mr-2" />
              My Resume!
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
