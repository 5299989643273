import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceTypo" style={{ padding: "0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            FONTS AND TYPOGRAPHY TOOLS
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/googlefonts.png" alt="shank_google_fonts" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://fonts.google.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Google Fonts</h5>
                                    </a>
                                    <p>The #1 resource for free and easy-to-use webfonts. Has a huge library of fonts</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/fontsquirrel.webp" alt="shank_fontsquirrel" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.fontsquirrel.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Fontsquirrel</h5>
                                    </a>
                                    {/*<i className="fas fa-code" />*/}
                                    <p>The best, 100% free fonts for commercial use. Another well-known huge font library.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/myfonts.png" alt="shank_myfonts" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.myfonts.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>MyFonts</h5>
                                    </a>
                                    <p>World's largest library of premium fonts, if you need more than free fonts for your next project.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/typetester.png" alt="shank_typetester" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.typetester.org/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>TypeTester</h5>
                                    </a>
                                    <p>Web application for testing and comparing more than 2200 typefaces.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/typeguide.svg" alt="shank_typeguide" style={{ marginBottom: '1rem' }}></img>
                                    <a href="http://www.typogui.de/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>A Pocket Guide to Typography</h5>
                                    </a>
                                    <p>Amazing resource to learn about typography. Learn typography basics in less than 30 minutes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
