import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceNodejs" style={{ padding: "0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            NODE.JS DEVELOPMENT
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/node-best-practices.jpg" alt="shank_node-best-practices" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://github.com/goldbergyoni/nodebestpractices?utm_source=mybridge&utm_medium=blog&utm_campaign=read_more" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Node.js Best Practices </h5>
                                    </a>
                                    <p>Huge list of best practices for building Node apps. Important for big projects.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/awesome-node.png" alt="shank_awesome-node" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://github.com/sindresorhus/awesome-nodejs" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Awesome Node.js </h5>
                                    </a>
                                    {/*<i className="fas fa-code" />*/}
                                    <p>Want to use more packages than you learned in my course? This is a great list to get you started!</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/node-weekly.png" alt="shank_node-weekly" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://nodeweekly.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Node Weekly Newsletter </h5>
                                    </a>
                                    <p>This is how I stay informed about everyhting happening in the Node.js world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
