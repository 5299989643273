import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceColor" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            COLORS AND TOOLS
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/flatuicolors.png" alt="shank_flatuicolor" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://flatuicolors.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Flat UI Colors</h5>
                                    </a>
                                    <p>A good starting point for choosing a flat design color for your next project.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/materialpalette.jpg" alt="shank_materialpalette" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.materialpalette.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Material Palette</h5>
                                    </a>
                                    <p>Another great set of colors, inspired in material design. Generate and download your palette.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/colorhunt.gif" alt="shank_colorhunt" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://colorhunt.co/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Colorhunt Palettes</h5>
                                    </a>
                                    <p>Don't know what colors to use for your website? Colorhunt helps you with beautiful color palettes.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/lolcolours.png" alt="shank_typetester" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.webdesignrankings.com/resources/lolcolors/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>LOL Colors Palettes</h5>
                                    </a>
                                    <p>Curated color palettes inspiration. Another source for great color palettes.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/uigradients.png" alt="shank_typetester" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://uigradients.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>UI Gradients</h5>
                                    </a>
                                    <p>Collection of beautiful color gradients for you to choose from and export to your project.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
