import React, { Component } from "react";

class NativeAppModalOne extends Component {
  render() {
    return (
      <div className="portfolio-modal mfp-hide" id="nativeapp-modal-1">
        <div className="portfolio-modal-dialog bg-white">
          {/*eslint-disable-next-line*/}
          <a
            className="close-button d-none d-md-block portfolio-modal-dismiss"
            href="#"
          >
            <i className="fa fa-3x fa-times" />
          </a>
          <div className="container text-center">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="text-secondary text-uppercase mb-0">
                  Contact / Phonebook Mobile App
                </h2>
                <hr className="star-dark mb-5" />
                <img
                  className="img-fluid mb-5 m-md-3"
                  src="img/portfolio/projects/Phonebook-app/Add-Contact.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5 m-md-3"
                  src="img/portfolio/projects/Phonebook-app/Edit-Contact.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5 m-md-3"
                  src="img/portfolio/projects/Phonebook-app/View-Contact.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5 m-md-3"
                  src="img/portfolio/projects/Phonebook-app/Home.jpg"
                  alt=""
                />
                <p className="mb-5">
                  Its a phonebook app, built using <strong>React Native</strong>
                  .
                  <br />
                  We can Add, View, Edit and Delete any contact, also it can
                  directly call or send sms to the user right from the phonebook
                  itself.
                </p>
                <hr />
                <br />
                <a
                  href="https://exp-shell-app-assets.s3.us-west-1.amazonaws.com/android/%40shank5060/contactApp-3b2681e49e284ad485d92134356e6c1c-signed.apk"
                  //target="_blank"
                  rel="noopener noreferrer"
                  className="mr-3"
                >
                  <button className="btn btn-primary btn-lg rounded-pill">
                    Download Apk
                  </button>
                </a>
                {/*eslint-disable-next-line*/}
                <a
                  className="btn btn-danger btn-lg rounded-pill portfolio-modal-dismiss close-btn"
                  href="#"
                >
                  <i className="fa fa-close" />
                  Close Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NativeAppModalOne;
