import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="footer text-center">
        <div className="container">
          <div className="row">
            {/*<div className="col-md-6 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Location</h4>
              <p className="lead mb-0">
                Noida
                <br />
                201303
              </p>
    </div>*/}
            <div className="col-md-12 mb-5 mb-lg-0">
              <h4 className="text-uppercase mb-4">Contact ME</h4>
              <ul className="list-inline mb-0">
                {/*<li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="#"
                  >
                    <i className="fab fa-fw fa-facebook-f" />
                  </a>
    </li>*/}
                {/*<li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="#"
                  >
                    <i className="fab fa-fw fa-google-plus-g" />
                  </a>
  </li>*/}
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="https://github.com/shankcode"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-fw fa-github" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="https://www.linkedin.com/in/shashank-yadav-5060"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-fw fa-linkedin-in" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="https://angel.co/shank5060"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-angellist" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="https://expo.io/@shank5060"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fas fa-angle-up"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="tel:8700086088"
                    rel="noopener noreferrer"
                  >
                    <i class="fas fa-phone"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn btn-outline-light btn-social text-center rounded-circle"
                    href="https://api.whatsapp.com/send?phone=918700086088&text=Hello Shashank, lets have a chat!&lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="fab fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
