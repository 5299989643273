import React, { Component } from "react";

class DesignGrid extends Component {
  render() {
    return (
      <section className="portfolio" id="design">
        <div className="container">
          <h2 className="text-center text-uppercase text-secondary mb-0">
            Sketch / XD Designs
          </h2>
          <hr className="star-dark mb-5" />
          <div className="row text-center">
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#design-modal-1"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/XD-Designs/App-Start-Pages/3.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#design-modal-2"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/XD-Designs/News-App/1.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#design-modal-3"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/XD-Designs/Radio-App.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#design-modal-4"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/XD-Designs/Blog-Login-Screen.png"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#design-modal-5"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/XD-Designs/Chat-App/Dating-Chat-App-2.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DesignGrid;
