import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceIcon" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            BEST ICONS AND TOOLS
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/ionicons.png" alt="shank_isorepublic" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="http://ionicons.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Ionicons</h5>
                                    </a>
                                    <p>My personal favorite #1 icon font, 100% free even for commercial usage.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/fontawesome.ico" alt="shank_isorepublic" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="http://fontawesome.io/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Font Awesome</h5>
                                    </a>
                                    <p>Another highly popular icon font, consisting of 628 icons spread across several categories.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/flaticon.png" alt="shank_flaticon" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://www.flaticon.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Flaticon</h5>
                                    </a>
                                    <p>The largest database of free icons available in PNG, SVG, EPS and PSD. It's even searchable.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/iconmonstr.png" alt="shank_iconmonstr" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://iconmonstr.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Iconmonstr</h5>
                                    </a>
                                    <p>Over 3400 free and simple icons in 246 collections, all searchable.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/fontastic.png" alt="shank_fontastic" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://fontastic.me/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Fontastic</h5>
                                    </a>
                                    <p>Create a customized icon font from your icons. Choose from 9000 icons or import your own.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/iconjar.jpg" alt="shank_iconjar" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://geticonjar.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Iconjar</h5>
                                    </a>
                                    <p>A Mac app to organize, search and use icons the easy way. Works with Sketch, PS and AI.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/icomoon.png" alt="shank_icomoon" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://icomoon.io/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Icomoon</h5>
                                    </a>
                                    <p>A great app to convert icon fonts to SVG icons and sprites. I use it in my advanced CSS course.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
