import React, { Component } from "react";

class PortfolioGrid extends Component {
  render() {
    return (
      <section className="portfolio" id="portfolio">
        <div className="container">
          <h2 className="text-center text-uppercase text-secondary mb-0">
            Web Apps
          </h2>
          <hr className="star-dark mb-5" />
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-1"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Tatvdarshi-Portfolio-Assets/1.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-2"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Foodlicious/1.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-3"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/HTTP-Methods-Protfolio-Assets/1.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-4"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Newson/1.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-5"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Infotube/1.jpg"
                  alt=""
                />
              </a>
            </div>
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#portfolio-modal-6"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Wanderlust/1.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PortfolioGrid;
