import React, { Component } from "react";

class ModalSix extends Component {
  render() {
    return (
      <div className="portfolio-modal mfp-hide" id="portfolio-modal-6">
        <div className="portfolio-modal-dialog bg-white">
          {/*eslint-disable-next-line*/}
          <a
            className="close-button d-none d-md-block portfolio-modal-dismiss"
            href="#"
          >
            <i className="fa fa-3x fa-times" />
          </a>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 className="text-secondary text-uppercase mb-0">
                  Wanderlust - Live weather and top attractions App.
                </h2>
                <hr className="star-dark mb-5" />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Wanderlust/1.jpg"
                  alt=""
                />
                <p className="mb-5">
                  A ReactJS based web app to query Foursquare API to fetch live
                  weather and top attractions of any location.
                </p>
                <hr />
                <br />
                <a
                  href="https://wanderlust.shashanky.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-5 "
                >
                  <button className="btn btn-primary btn-lg rounded-pill">
                    Visit Project
                  </button>
                </a>
                {/*eslint-disable-next-line*/}
                <a
                  className="btn btn-danger btn-lg rounded-pill portfolio-modal-dismiss close-btn"
                  href="#"
                >
                  <i className="fa fa-close" />
                  Close Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalSix;
