import React, { Component } from "react";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import About from "../Components/About";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Skills from "../Components/Skills";
import NativeApps from "../Components/Native_App_Grid";
import PortfolioGrid from "../Components/Portfolio_Grid";
import DesignGrid from "../Components/Design_Grid";
import ModalOne from "../Components/Portfolio Modals/ModalOne";
import ModalTwo from "../Components/Portfolio Modals/ModalTwo";
import ModalThree from "../Components/Portfolio Modals/ModalThree";
import ModalFour from "../Components/Portfolio Modals/ModalFour";
import ModalFive from "../Components/Portfolio Modals/ModalFive";
import ModalSix from "../Components/Portfolio Modals/ModalSix";
import DesignModalOne from "../Components/Designs Modals/D_ModalOne";
import DesignModalTwo from "../Components/Designs Modals/D_ModalTwo";
import DesignModalThree from "../Components/Designs Modals/D_ModalThree";
import DesignModalFour from "../Components/Designs Modals/D_ModalFour";
import DesignModalFive from "../Components/Designs Modals/D_ModalFive";

import NativeAppModalOne from "../Components/Native-Apps/NativeApp_ModalOne";
import NativeAppModalTwo from "../Components/Native-Apps/NativeApp_ModalOne";
import NativeAppModalThree from "../Components/Native-Apps/NativeApp_ModalOne";
import MetaTagsComponent, { Template } from "../MetaComponent";

class Home extends Component {
    constructor(props){
        super(props)

        this.schema = [...Template, {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Shashank | MERN Stack Developer | FullStack Developer",
            "description": "This is Shashank Yadav, a MERN Stack developer, I write code! Frontend & Backend business logics. Flexboxing, Gridding, Sassing, Componentizing, Fetching, Promising, Routing, Querying are few keywords better describes my role."
        }]
    }
    render() {
        return (
            <div>
                <MetaTagsComponent 
                    title="Shashank | MERN Stack Developer" 
                    description="This is Shashank Yadav, a MERN Stack developer, I write code! Frontend & Backend business logics. Flexboxing, Gridding, Sassing, Componentizing, Fetching, Promising, Routing, Querying are few keywords better describes my role."
                    keywords="Shashank, Shashank Yadav, Shashank Developer, Shashank Mern Developer, Full stack developer, React Expert, Shashank React, Remote Development Mern, Shashank Remote Developer, Reactjs Shashank"
                    schema={this.schema}
                    location={window.location}
                />
                <Navbar />
                <Header />
                <Skills />
                <PortfolioGrid />
                <NativeApps />
                <DesignGrid />
                <About />
                {/*<Contact />*/}
                <Footer />

                <div className="copyright py-4 text-center text-white">
                    <div className="container">
                        <small>Copyright &copy; Shashank Yadav 2019</small>
                    </div>
                </div>

                {/*<!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) -->*/}
                <div className="scroll-to-top d-lg-none position-fixed ">
                    <a
                        className="js-scroll-trigger d-block text-center text-white rounded"
                        href="#page-top"
                    >
                        <i className="fa fa-chevron-up" />
                    </a>
                </div>

                {/*<!-- Project Modals -->*/}
                <ModalOne />
                <ModalTwo />
                <ModalThree />
                <ModalFour />
                <ModalFive />
                <ModalSix />

                {/*<!-- Native App Modals -->*/}
                <NativeAppModalOne />
                <NativeAppModalTwo />
                <NativeAppModalThree />

                {/*<!-- Design Modals -->*/}
                <DesignModalOne />
                <DesignModalTwo />
                <DesignModalThree />
                <DesignModalFour />
                <DesignModalFive />
            </div>
        );
    }
}

export default Home;