import React, { Component } from "react";

class DesignModalOne extends Component {
  render() {
    return (
      <div className="portfolio-modal mfp-hide" id="design-modal-1">
        <div className="portfolio-modal-dialog bg-white">
          {/*eslint-disable-next-line*/}
          <a
            className="close-button d-none d-md-block portfolio-modal-dismiss"
            href="#"
          >
            <i className="fa fa-3x fa-times" />
          </a>
          <div className="container text-center">
            <div className="row">
              <div className="col-md-8 mx-auto">
                <h2 className="text-secondary text-uppercase mb-0">
                  Onboarding tutorial App screens.
                </h2>
                <hr className="star-dark mb-5" />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/XD-Designs/App-Start-Pages/1.png"
                  alt=""
                />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/XD-Designs/App-Start-Pages/2.png"
                  alt=""
                />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/XD-Designs/App-Start-Pages/3.png"
                  alt=""
                />
                <p className="mb-5">These are designed using Adobe XD.</p>
                {/*eslint-disable-next-line*/}
                <a
                  className="btn btn-danger btn-lg rounded-pill portfolio-modal-dismiss"
                  href="#"
                >
                  <i className="fa fa-close" />
                  Close Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DesignModalOne;
