import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceImageVideo" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            IMAGES AND VIDEOS
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/unsplash.svg" height="50" alt="shank_unsplash" style={{ marginBottom: '1rem' }}></img>
                                    <a href="https://unsplash.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Unsplash</h5>
                                    </a>
                                    <p>My #1 resource for free high-resolution photos. 10 new photos every 10 days.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/isorepublic.png" alt="shank_isorepublic" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://isorepublic.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>ISO Republic</h5>
                                    </a>
                                    <p>Free and premium stock photos for your website. Easily searchable by topic.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/pixaby.png" alt="shank_pixabay" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://pixabay.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Pixaby</h5>
                                    </a>
                                    <p>Over 620,000 free stock photos, vectors and art illustrations you can use anywhere.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/subtlepatterns.png" alt="shank_typetester" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.toptal.com/designers/subtlepatterns/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Subtle Patterns</h5>
                                    </a>
                                    <p>High quality resource of tilable textured patterns, completely free to use.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/coverr.png" alt="shank_coverr" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.coverr.co/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Coverr</h5>
                                    </a>
                                    <p>Best resource for beautiful and free videos for your website. 7 new videos added every monday.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/placeit.jpeg" alt="shank_placeit" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://placeit.net/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>PlaceIt</h5>
                                    </a>
                                    <p>Instant iPhone and Macbook mockups. Just place your screenshot on a device, no Photoshop.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/canva.png" alt="shank_typetester" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.canva.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Canva</h5>
                                    </a>
                                    <p>Easily create beautiful designs with drag-and-drop features and professional layouts.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
