import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="htmlcssjs" style={{ marginTop: "6rem" }}>
                <div className="container">
                    <div>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            HTML5/CSS/JS
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row">
                            <div className="text-center d-block mx-auto skill-technology" style={{ marginBottom: '2rem' }}>
                                HTML
                            </div>
                            <br />
                            <div className="row justify-content-center">
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/css-tricks-3.png" height="50" alt="shank_csstrick" style={{ marginBottom: '1rem' }}></img>
                                        <a href="https://css-tricks.com/snippets/html/glyphs/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>HTML Entity Glyphs by CSS-Tricks</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Super useful reference, gives you HTML entitiy name, numeric code, hex code and ISO code</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/html-cheat.png" height="50" alt="shank_htmlcheat" style={{ marginBottom: '1rem' }}></img>
                                        <a href="https://websitesetup.org/html5-cheat-sheet/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>HTML Cheat Sheet</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>A very handy printable HTML5 cheat sheet that can save you a ton of time</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text-center d-block mx-auto skill-technology" style={{ marginBottom: '2rem' }}>
                                CSS
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/codrops.png" alt="" style={{ marginBottom: '1rem' }} height="50" />
                                        <a href="http://tympanus.net/codrops/css_reference/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>CSS3 Reference by Codrops</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Another excellent CSS3 reference, this time from Codrops. Make sure to check this one out, too.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/caniuse.png" alt="shank_caniuse" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="https://caniuse.com/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>Can I Use?</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Up-to-date browser support tables for front-end technologies on desktop and mobile browsers.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/cssselectors.jpeg" alt="shank_cssselector" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="https://code.tutsplus.com/tutorials/the-30-css-selectors-you-must-memorize--net-16048" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>30 CSS Selectors by Tutplus</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>I find myself using this handy list of the 30 most important CSS selectors from Tutplus all the time.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/animatecss.png" alt="shank_animatecss" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="https://daneden.github.io/animate.css/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>Animate.css</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Just-add-water CSS animations. A simple CSS library that lates you add animations with ease.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/responsivegrid.png" alt="shank_responsivegrid" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="http://www.responsivegridsystem.com/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>Responsive Grid System</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>A quick, flexible and easy fluid grid for spectacularly easy responsive web design.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/css.svg" alt="shank_css" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="http://paulcpederson.com/articles/css-for-people-who-hate-css" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>CSS for People Who Hate CSS</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Excellent guide on how to write better, cleaner and more reusable CSS code.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/clippy.png" alt="shank_clippy" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="http://bennettfeely.com/clippy/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>Clippy</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>A small tool to help you using the new and powerful clip-path property.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/cubic.png" alt="shank_cubicbezire" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="http://cubic-bezier.com/#.17,.67,.83,.67" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>Cubic-bezier function generator</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>A tool for visually generating timing animation functions to use in CSS transitions and animations.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/easings.png" alt="" height="50" style={{ marginBottom: '1rem' }} />
                                        <a href="http://easings.net/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>CSS easing functions</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>An amazing collection of easing functions bo be used in CSS transitions and animations.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text-center d-block mx-auto skill-technology" style={{ marginBottom: '2rem' }}>
                                Javascript
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/mdn.png" alt="shank_operatorpredicence" style={{ marginBottom: '1rem' }} height="50" />
                                        <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Operator_Precedence" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>JavaScript Operator Precedence Table</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Very handy cheatsheet to determine which JavaScript operators are evaluated first.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/dom.png" alt="shank_dommanipulation" style={{ marginBottom: '1rem' }} height="50" />
                                        <a href="http://youmightnotneedjquery.com/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>DOM Manipulation Reference</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Called "You Might Not Need jQuery", but I use this as a complete DOM manipulation reference.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/js-patterns.png" alt="shank_jsdesignpattern" style={{ marginBottom: '1rem' }} height="50" />
                                        <a href="https://addyosmani.com/resources/essentialjsdesignpatterns/book/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>JavaScript Design Patterns</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>For more advanced developers: learn all common JavaScript design patterns. Perfect reference.</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="text-center">
                                        <img src="img/resources/jses6.png" alt="" style={{ marginBottom: '1rem' }} height="50" />

                                        <a href="https://kangax.github.io/compat-table/es6/" target="_blank" style={{ color: '#ff951f' }}>
                                            <h5>ES6 Browser Compatibility Table</h5>
                                        </a>
                                        {/*<i className="fas fa-code" />*/}
                                        <p>Check out all the new ES2015/ES6 features supported by the most popular browsers.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
