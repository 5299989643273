import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import "./App.css";

// Import Routes
import Home from "./Routes/Home";
import Resources from "./Routes/Resources"

class App extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/resources" component={Resources} />
					<Route path="*" render={() =>
						<div className="d-flex justify-content-center align-items-center w-100" style={{ height: '100vh' }}>
							<h3>404 &nbsp;|</h3>
							<h6>&nbsp; &nbsp;Not Found</h6>
						</div>
					} />
				</Switch>
			</Router>
		);
	}
}

export default App;
