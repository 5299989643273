import React, { Component } from 'react';

class Skills extends Component {
  render() {
    return (
      <section className="skills" id="skills">
        <div className="container">
          <h2 className="text-center text-uppercase text-secondary mb-0">
            Skills
          </h2>
          <hr className="star-dark mb-5" />
          <div className="row  justify-content-center align-items-center">
            {/**------ Technologies ----------*/}
            <div className="col-md-12">
              <div className="mb-3 text-center d-block mx-auto skill-technology">
                Technologies
              </div>
              <hr />
              <ul className="list-inline d-flex justify-content-around">
                <li className="text-center">
                  <i className="fas fa-code" />
                  <hr />
                  HTML / SASS
                </li>
                <li className="text-center">
                  <i className="fab fa-js-square" />
                  <hr />
                  ReactJS / Redux / ES6/7/8/9/10(JS)
                </li>
                <li className="text-center">
                  <i className="fab fa-node" />
                  <hr />
                  NodeJS / ExpressJS
                </li>
                <li className="text-center">
                  <i className="fas fa-database" />
                  <hr />
                  MongoDB
                </li>
                <li className="text-center">
                  <i class="fab fa-docker" />
                  <hr />
                  Docker / Kubernetes (Basics)
                </li>
              </ul>
            </div>
            {/**------ TOOLS ----------*/}
            <div className="col-md-12">
              <div className="mb-3 text-center d-block mx-auto skill-tool">
                Tools
              </div>
              <hr />
              <ul className="list-inline d-flex justify-content-around">
                <li className="text-center">
                  <i className="fab fa-sketch" />
                  <hr />
                  Sketch / XD
                </li>
                <li className="text-center">
                  <i className="fab fa-git" />
                  <hr />
                  Git / Github / Bitbucket
                </li>
                <li className="text-center">
                  <i class="fab fa-confluence" />
                  <hr />
                  Jira / Confluence
                </li>
                <li className="text-center">
                  <i className="fab fa-npm" />
                  <hr />
                  NPM
                </li>
                <li className="text-center">
                  <i className="fas fa-cloud-upload-alt" />
                  <hr />
                  Heroku / AWS
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Skills;
