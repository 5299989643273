import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="masthead bg-primary text-white text-center">
        <div className="container">
          <div>
            <img
              style={{ width: "290px" }}
              id="img"
              className="img-fluid mb-5 d-block mx-auto rounded-circle"
              src="img/Profile3.jpg"
              alt="Profile Pic"
            />
          </div>
          <h1 className="text-uppercase mb-0">Shashank Yadav</h1>
          <div class="divider-custom divider-light">
            <div class="divider-custom-line"></div>
            <div class="divider-custom-icon">
              <i class="fas fa-star"></i>
            </div>
            <div class="divider-custom-line"></div>
          </div>
          <h2 className="font-weight-light mb-0">
            Web Developer - User Interface Designer - User Experience Designer
          </h2>
        </div>
      </header>
    );
  }
}

export default Header;
