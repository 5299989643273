import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceTypo" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            POPULAR BLOGS & COMMUNITIES
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/smashingmagazine.png" alt="shank_smashingmagzine" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.smashingmagazine.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Smashing Magazine</h5>
                                    </a>
                                    <p>A very popular web design and development blog, writing about all things coding and designing.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/codrops.png" alt="shank_codrops" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="http://tympanus.net/codrops/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Codrops</h5>
                                    </a>
                                    <p>Another blog, writing articles and tutorials about latest web trends, techniques and new possibilities.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/webdesignerdepot.png" alt="shank_webdesignerdepot" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://www.webdesignerdepot.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Web Designer Depot</h5>
                                    </a>
                                    <p>The very best in web design news, views, techniques, and resources.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/frontendfront.png" alt="shank_frontendfront" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://frontendfront.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Front End Front</h5>
                                    </a>
                                    <p>A place where front-end developers can ask questions, share links, and show their work.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/heydesigner.png" alt="shank_heydesigner" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://heydesigner.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Hey Designer</h5>
                                    </a>
                                    <p>Curated articles for designers and front-end developers.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/designernews.png" alt="shank_designernews" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.designernews.co/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Designer News</h5>
                                    </a>
                                    <p>A community of people in design and tech, to discuss and share interesting things in the industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
