import React, { Component } from "react";

class ModalTwo extends Component {
  render() {
    return (
      <div className="portfolio-modal mfp-hide" id="portfolio-modal-2">
        <div className="portfolio-modal-dialog bg-white">
          {/*eslint-disable-next-line*/}
          <a
            className="close-button d-none d-md-block portfolio-modal-dismiss"
            href="#"
          >
            <i className="fa fa-3x fa-times" />
          </a>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 className="text-secondary text-uppercase mb-0">
                  FoodLicious - Search, LOCATION based Businesses.
                </h2>
                <hr className="star-dark mb-5" />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Foodlicious/1.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Foodlicious/2.jpg"
                  alt=""
                />
                <p className="mb-5">
                  It's a REACT based responsive Web App to SEARCH businesses
                  (Restaurant, Saloon, Spa, Entertainment etc...) near any
                  location user wants, it uses YELP API to fetch details. User
                  can SORT businesses based on BEST MATCH, HIGHEST RATED, MOST
                  REVIEWED.
                </p>
                <hr />
                <br />
                <a
                  href="https://foodlicious.shashanky.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-5"
                >
                  <button className="btn btn-primary btn-lg rounded-pill">
                    Visit Project
                  </button>
                </a>
                {/*eslint-disable-next-line*/}
                <a
                  className="btn btn-danger btn-lg rounded-pill portfolio-modal-dismiss close-btn"
                  href="#"
                >
                  <i className="fa fa-close" />
                  Close Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalTwo;
