import React, { Component } from "react";

class ResourceNav extends Component {
    render() {
        return (
            <nav
                className="navbar navbar-expand-lg bg-secondary fixed-top text-uppercase"
                id="mainNav"
            >
                <div className="w-100">
                    <div className="text-center">
                        <a className="navbar-brand js-scroll-trigger" href="/resources">
                            Resources
                        </a>
                    </div>

                    <button
                        className="navbar-toggler navbar-toggler-right text-uppercase bg-primary text-white rounded"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        Menu <i className="fas fa-bars" />
                    </button>
                    <div className="collapse navbar-collapse w-100 justify-content-center" id="navbarResponsive">
                        <ul className="navbar-nav">
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#htmlcssjs"
                                >
                                    HTML/CSS/JS
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceTypo"
                                >
                                    TYPOGRAPHY
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceColor"
                                >
                                    COLORS
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceImageVideo"
                                >
                                    IMAGES/VIDEOS
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceIcon"
                                >
                                    ICONS
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceDesignInsp"
                                >
                                    DESIGN
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourcePlanTestOpt"
                                >
                                    TEST/OPTIMIZE/DEPLOY
                                </a>
                            </li>
                            <li className="nav-item mx-0 mx-lg-1">
                                <a
                                    className="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                    href="#resourceNodejs"
                                >
                                    NODE.JS
                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default ResourceNav;
