import React, { Component } from "react";

class ModalOne extends Component {
  render() {
    return (
      <div className="portfolio-modal mfp-hide" id="portfolio-modal-1">
        <div className="portfolio-modal-dialog bg-white">
          {/*eslint-disable-next-line*/}
          <a
            className="close-button d-none d-md-block portfolio-modal-dismiss"
            href="#"
          >
            <i className="fa fa-3x fa-times" />
          </a>
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 className="text-secondary text-uppercase mb-0">
                  Complete M-E-R-N Stack Web App.
                </h2>
                <hr className="star-dark mb-5" />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Tatvdarshi-Portfolio-Assets/1.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Tatvdarshi-Portfolio-Assets/3.jpg"
                  alt=""
                />
                <img
                  className="img-fluid mb-5"
                  src="img/portfolio/projects/Tatvdarshi-Portfolio-Assets/2.jpg"
                  alt=""
                />
                <p className="mb-5">
                  Its a complete MERN Stack based fully responsive web app,
                  where user can LOGIN/SIGNUP either using email or social media
                  (GOOGLE, FACEBOOK). Only EMAIL VERIFIED user can Login and
                  access his/her DASHBOARD, where he can manage his Profile
                  info, Address and Payment details. User can make PAYMENT using
                  PAYTM & INSTAMOJO PAYMENT GATEWAYS and can access generated
                  PDF INVOICE from dashboard at anytime. This app also has
                  OFFLINE, PUSH NOTIFICATION and ADD TO HOME SCREEN capabilities
                  (Service Worker Integrated).
                </p>
                <hr />
                <br />
                <a
                  href="https://tatvdarshiu.shashanky.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mr-5"
                >
                  <button className="btn btn-primary btn-lg rounded-pill">
                    Visit Project
                  </button>
                </a>
                {/*eslint-disable-next-line*/}
                <a
                  className="btn btn-danger btn-lg rounded-pill portfolio-modal-dismiss close-btn"
                  href="#"
                >
                  <i className="fa fa-close" />
                  Close Project
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalOne;
