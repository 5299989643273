import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourceDesignInsp" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            FIND DESIGN INSPIRATION
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/dribbble.png" alt="shank_dribbble" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://dribbble.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Dribbble</h5>
                                    </a>
                                    <p>The ultimate design inspiration resource, not just for web design. The show and tell for designers.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/landbook.jpg" alt="shank_landbook" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="http://land-book.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Land Book</h5>
                                    </a>
                                    <p>Product landing page gallery. My #1 inspiration resource when I build a landing page.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/onepagelove.png" alt="shank_onepagelover" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://onepagelove.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>One Page Love</h5>
                                    </a>
                                    <p>The ultimate showcase of delicious One Page websites.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/siteinspire.png" alt="shank_siteinspire" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.siteinspire.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Site Inspire</h5>
                                    </a>
                                    <p>A showcase of the finest web and interactive design. Currently over 4900 websites.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/calltoidea.png" alt="shank_fontastic" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="http://www.calltoidea.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Call To idea</h5>
                                    </a>
                                    <p>Get quick inspiration for common website elements such as forms, sliders, navigations, etc.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/mediaqueries.png" alt="shank_mediaquries" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://mediaqueri.es/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Media Queries</h5>
                                    </a>
                                    <p>A collection of responsively designed websites for inspiration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
