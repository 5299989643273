import React, { Component } from "react";

class NativeApps extends Component {
  render() {
    return (
      <section className="portfolio" id="nativeapps">
        <div className="container">
          <h2 className="text-center text-uppercase text-secondary mb-0">
            React Native Apps
          </h2>
          <hr className="star-dark mb-5" />
          <div className="row text-center">
            <div className="col-md-6 col-lg-4">
              <a
                className="portfolio-item d-block mx-auto"
                href="#nativeapp-modal-1"
              >
                <div className="portfolio-item-caption d-flex position-absolute h-100 w-100">
                  <div className="portfolio-item-caption-content my-auto w-100 text-center text-white">
                    <i className="fas fa-search-plus fa-3x" />
                  </div>
                </div>
                <img
                  className="img-fluid"
                  src="img/portfolio/projects/Phonebook-app/View-contact.jpg"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default NativeApps;
