import React, { Component } from 'react';

class HTMLCSSJS extends Component {
    render() {
        return (
            <section className="skills" id="resourcePlanTestOpt" style={{ padding: "2rem 0" }}>
                <div className="container">
                    <div style={{ marginTop: "4rem" }}>
                        <h2 className="text-center text-uppercase text-secondary mb-0">
                            PLANNING, TESTING, OPTIMIZATION AND DEPLOYMENT
                        </h2>
                        <hr className="star-dark mb-5" />
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/periodictable.png" alt="shank_periodictable" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.newdesigngroup.ca/website-design/web-design-process-infographic/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Periodic Table of Web Design Process</h5>
                                    </a>
                                    <p>Great overview of the web design process: from project brief to launching the final version.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/w3c.png" alt="shank_htmlvalidate" height="50" style={{ marginBottom: '1rem' }} />
                                    <a href="https://validator.w3.org/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>HTML Validator by W3C</h5>
                                    </a>
                                    <p>Check if your HTML markup is valid and contains no errors. Official W3C tool.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/w3c.png" alt="shank_cssvalidate" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://jigsaw.w3.org/css-validator/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>CSS Validator by W3C</h5>
                                    </a>
                                    <p>Check if your CSS code is valid and contains no errors. Official W3C tool.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/optimizilla.png" alt="shank_optimizilla" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://imagecompressor.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Optimizilla</h5>
                                    </a>
                                    <p>Compress up to 20 JPEG and PNG images while keeping a good level of quality.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/favicongenerator.png" alt="shank_favicongenerator" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://realfavicongenerator.net/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Real favicon Generator</h5>
                                    </a>
                                    <p>Just upload an image and this tool creates favicons for your website for all platforms.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/usabilitychecklist.ico" alt="shank_usabilitychecklist" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://teamsuccess.io/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Usability checklist</h5>
                                    </a>
                                    <p>Catch common usability problems of your website before you deploy it. Very useful.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/googledev.png" alt="shank_googledev" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.thinkwithgoogle.com/feature/testmysite/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Google Mobile-Friendly Test</h5>
                                    </a>
                                    <p>Test if your webpage has a mobile-friendly design, according to Google's ranking factors.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/googledev.png" alt="shank_googledev" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Google PageSpeed Insights</h5>
                                    </a>
                                    <p>Quick tool to test your webpage for performance on all devices.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/nibbler.png" alt="shank_nibbler" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://nibbler.silktide.com/en_US" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Nibbler</h5>
                                    </a>
                                    <p>Website testing tool for 10 key areas including accessibility, SEO, social media and technology.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/woorank.png" alt="shank_woorank" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://www.woorank.com/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Woorank</h5>
                                    </a>
                                    <p>Get a review of your website to address issues and identify opportunities to get ahead of competition.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/googleanalytics.png" alt="shank_googleanalytics" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://marketingplatform.google.com/about/analytics/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Google Analytics</h5>
                                    </a>
                                    <p>Get free insights about traffic, visitors and conversions. A must for every website.</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/optimization-guide.png" alt="shank_optimization-guide" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://kinsta.com/learn/page-speed/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Website Speed Optimization Guide</h5>
                                    </a>
                                    <p>An excellent guide to optimize your webpage speed. This is something many devs overlook!</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="text-center">
                                    <img src="img/resources/sizzy.png" alt="shank_sizzy" height='50' style={{ marginBottom: '1rem' }} />
                                    <a href="https://sizzy.co/" target="_blank" style={{ color: '#ff951f' }}>
                                        <h5>Sizzy</h5>
                                    </a>
                                    <p>A tool for developing responsive websites crazy-fast. See your page on multiple devices at once.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HTMLCSSJS;
