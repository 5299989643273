import React, { Component } from 'react';
import ResourceBlog from "./resBlogCommunity";
import ResourceColor from "./ResColor";
import ResourceDesign from "./resDesignInspiration";
import ResourceIcon from "./resIconTool";
import ResourceImageVideo from "./resImageVideo";
import ResourceHTMLCSSJS from "./ResHTMLCSSJS";
import ResourceTypo from "./ResTypo";
import ResourcePlanTestOptDep from "./resPlanTestOptDepl";
import ResourceNodeJS from "./resNodejs";

class ResourceIndex extends Component {
    render() {
        return (
            <>
                <ResourceHTMLCSSJS />
                <ResourceTypo />
                <ResourceColor />
                <ResourceImageVideo />
                <ResourceIcon />
                <ResourceDesign />
                <ResourceBlog />
                <ResourcePlanTestOptDep />
                <ResourceNodeJS />
            </>
        );
    }
}

export default ResourceIndex;
