import React from "react";

import ResourceNav from "../Components/Resources/ResourceNav";
import ResHTMLCSSJS from "../Components/Resources/index";


export class Resources extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <ResourceNav />
                <ResHTMLCSSJS />
            </React.Fragment>
        )
    }
}

export default Resources;