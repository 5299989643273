import React from 'react';
import MetaTags from 'react-meta-tags';
import propTypes from 'prop-types';

const MetaComponent = (props) => {
	let canonicalURL = props.location ? `${props.location.protocol}//${props.location.host}${window.location.pathname}` : '';

	return (
		<MetaTags>
			{props.location && <link rel="canonical" href={`${canonicalURL}`} />}
			<title>{props.title}</title>
			<meta name="description" content={props.description} />
			<meta name="keywords" content={props.keywords} />
			<meta property="og:title" content={props.title} />
			<meta property="og:type" content="website" />
			<meta name="og:description" content={props.description} />
			<meta property="og:url" content={window.location.href} />
			<meta property="og:site_name" content={'content'} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:site" content="@" />
			<meta name="twitter:title" content={props.title} />
			<meta name="twitter:description" content={props.description} />
			<meta name="twitter:image" content="" />
			{props.schema && <JsonLd data={props.schema} />}
		</MetaTags>
	);
}

const JsonLd = ({ data }) => {
	return (
		data.map((schema, index) => {
			return (
				<script
					key={index}
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
				/>
			)
		})
	);
};

export let Template = [{
    "@context":"http://schema.org","@type":"WebSite","name":"Shashank Yadav","url":"https://www.shashanky.com"
}, {
    "@context": "http://schema.org/",
    "@type": "Organization",
    "name": "Shashank Yadav MERN Developer",
    "url": "https://www.shashanky.com",
    "logo": "https://www.shashanky.com/icon.png",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Sacchidanand Kunj",
        "addressLocality": "Pardaha Block Road, Mau",
        "addressRegion": "Uttar Pradesh",
        "postalCode": "275101"
    },
    "alternateName": [
        "Shashank",
        "Shashank Yadav",
		"Shashank MERN Stack"
    ]
}]


MetaComponent.propTypes = {
	title: propTypes.string,
	keywords: propTypes.string,
	description: propTypes.string,
	image: propTypes.string,
	schema: propTypes.arrayOf(propTypes.object),
	location: propTypes.object
};

JsonLd.propTypes = {
	data: propTypes.arrayOf(propTypes.object),
};

export default MetaComponent;
