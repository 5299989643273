// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

export function register() {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      // Is not localhost. Just register service worker
      registerValidSW(swUrl);
    });
  }
}

//--------------------- To Register SW -------------------//
function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(
      registration => {
        console.log(
          "Service Worker registration successful",
          registration.scope
        );
        //Call push notification
        subscribePush();
      },
      err => {
        console.log("Service Worker registration failed", err);
      }
    )
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

//----------------------- PUSH Subscription ---------------------//
function subscribePush() {
  navigator.serviceWorker.ready.then(registration => {
    if (!registration.pushManager) {
      alert("Push Unsupported");
      return;
    }
    registration.pushManager
      .subscribe({
        userVisibleOnly: true, //Always display notifications
        applicationServerKey: convertedVapidKey
      })
      .then(subscription => {
        fetch("/push/register", {
          method: "POST",
          body: JSON.stringify(subscription),
          headers: {
            "Content-Type": "application/json"
          }
        });
      })
      .catch(err => console.error("Push subscription error: ", err));
  });
}

//---------------- Convert URL Safe base64 string to a Uint8Array ---------------//
const vapidPublicKey =
  "BPpum5G2tkeMFzMBRQEtOzIrFGx5A4TN3H3pvYARaRXhoFTVlbSfo7jDHI43gXcy8_kZYI-zD48Da1a41ECWcDM";
const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    //eslint-disable-next-line
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

//----------------------- PUSH Un-Subscribe ---------------------//
/**
 * To Unsubscribe push notification, hook
 * unsubscribePush() to onClick button or anything.
 */
export function unsubscribePush() {
  navigator.serviceWorker.ready.then(registration => {
    //Find the registered push subscription in the service worker
    registration.pushManager
      .getSubscription()
      .then(subscription => {
        if (!subscription) {
          //If there isn't a subscription, then there's nothing to do
          return;
        }
        subscription
          .unsubscribe()
          .then(() =>
            fetch("/push/unregister", {
              method: "POST",
              body: JSON.stringify(subscription),
              headers: {
                "Content-Type": "application/json"
              }
            })
          )
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  });
}

//--------------------- To UN-Register SW -------------------//
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
